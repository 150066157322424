
import { defineComponent } from 'vue'
import Login from './components/Login.vue'
import Menu from './components/Menu.vue'
import Dashboard from './components/Dashboard.vue'
import Apps from './components/Apps.vue'
import Entities from './components/Entities.vue'
import Intersticiels from './components/Intersticiels.vue'
import Questions from './components/Questions.vue'
import Messages from './components/Messages.vue'

export default defineComponent({
  name: 'App',
  components: {
    Login,
    Menu,
    Dashboard,
    Apps,
    Entities,
    Questions,
    Intersticiels,
    Messages
  },
  data () {
    return {
      isLoggedIn: false,
      content: 'questions'
    }
  },
  mounted () {
    console.log('Backoffice mounted.')
    if (localStorage.user && localStorage.token) {
      this.isLoggedIn = true
    } else {
      this.isLoggedIn = false
    }
  },
  methods: {
    hasLoggedIn () {
      console.log('Logged In event received in App')
      this.isLoggedIn = true
    },
    hasLoggedOut () {
      console.log('Logged Out event received in App')
      this.isLoggedIn = false
    },
    contentSet (content: string) {
      console.log('contentSet event received in App : ' + content)
      this.content = content
    }
  }
})
