import axios from 'axios'
import authHeader from './auth-header'

const API_URL = 'https://api.callmepresident.fr/api/'

class BackofficeService {
  sendLogin (formData) {
    return axios.post(API_URL + 'user/login', formData, { headers: authHeader() })
  }

  updateEntity (id, formData) {
    return axios.post(API_URL + 'entity/' + id + '/update', formData, { headers: authHeader() })
  }

  updateQuestion (id, formData) {
    return axios.post(API_URL + 'question/' + id + '/update', formData, { headers: authHeader() })
  }

  updateResponse (questionId, responseId, formData) {
    return axios.post(API_URL + 'response/' + questionId + '/' + responseId + '/update', formData, { headers: authHeader() })
  }

  updateIntersticiel (id, formData) {
    return axios.post(API_URL + 'intersticiel/' + id + '/update', formData, { headers: authHeader() })
  }

  updateMessage (id, formData) {
    return axios.post(API_URL + 'message/' + id + '/update', formData, { headers: authHeader() })
  }

  getAppsContent () {
    return axios.get(API_URL + 'apps-alphabetical', { headers: authHeader() })
  }

  getEntitiesContent () {
    return axios.get(API_URL + 'entities', { headers: authHeader() })
  }

  getQuestionsContent () {
    return axios.get(API_URL + 'questions', { headers: authHeader() })
  }

  getReactionsContent (questionId, responseId) {
    return axios.get(API_URL + 'question/' + questionId + '/' + responseId + '/reactions', { headers: authHeader() })
  }

  getIntersticielsContent () {
    return axios.get(API_URL + 'intersticiels', { headers: authHeader() })
  }

  getMessagesContent () {
    return axios.get(API_URL + 'messages', { headers: authHeader() })
  }
}

export default new BackofficeService()
