
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import MessageEditor from './MessageEditor.vue'

interface MobileAppInterface {
  id: number
  name: string
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';

  constructor (id: number, name: string) {
    this.id = id
    this.name = name
  }
}
interface EntityInterface {
  id: number
  label: string
}
class Entity implements EntityInterface {
  id = 0;
  label = '';

  constructor (id: number, label: string) {
    this.id = id
    this.label = label
  }
}
interface MessageInterface {
  id: number
  entityId: number
  entity: Entity
  mobileAppId: number
  mobileApp: MobileApp
  textF: string
  textH: string
  popularity: number
  economy: number
  health: number
  geopolitics: number
  eod: number
}
class Message implements MessageInterface {
  id = 0;
  entityId = 0;
  entity = { id: 0, label: '' };
  mobileAppId = 0;
  mobileApp = { id: 0, name: '' };
  textF = ''
  textH = ''
  popularity = 0;
  economy = 0;
  health = 0;
  geopolitics = 0;
  eod = 0;

  constructor (id: number, entity: Entity, mobileApp: MobileApp, textF: string, textH: string, popularity: number, economy: number, health: number, geopolitics: number, eod: number) {
    this.id = id
    this.entityId = entity.id
    this.entity = entity
    this.mobileAppId = mobileApp.id
    this.mobileApp = mobileApp
    this.textF = textF
    this.textH = textH
    this.popularity = popularity
    this.economy = economy
    this.health = health
    this.geopolitics = geopolitics
    this.eod = eod
  }
}

export default defineComponent({
  name: 'Messages',
  emits: ['loggedOut'],
  components: {
    MessageEditor
  },
  data () {
    return {
      messages: [],
      message: null
    }
  },
  mounted () {
    console.log('Messages mounted.')
    this.messagesGet()
  },
  methods: {
    getImgUrl (img: string) {
      return 'assets/' + img
    },
    messagesGet () {
      BackofficeService.getMessagesContent().then(
        (responses: any) => {
          this.messages = responses.data.map(
            (response: any) => this.parseAsMessage(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.loggedOut()
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name
      )
    },
    parseAsEntity (response: any) {
      return new Entity(
        response.id,
        response.label
      )
    },
    parseAsMessage (response: any) {
      return new Message(
        response.id,
        this.parseAsEntity(response.entity),
        this.parseAsMobileApp(response.mobile_app),
        ((response.text_f !== '') ? JSON.parse(response.text_f) : response.text_f),
        ((response.text_h !== '') ? JSON.parse(response.text_h) : response.text_h),
        response.popularity,
        response.economy,
        response.health,
        response.geopolitics,
        response.eod
      )
    },
    getExtract (text: any) {
      if (text !== '') {
        text = this.convertDataToHtml(text.blocks)
        text = this.stripHTML(text)
        if (text.length > 50) {
          return text.substring(0, 50) + '...'
        } else {
          return text
        }
      } else {
        return text
      }
    },
    convertDataToHtml (blocks:any) {
      var convertedHtml = ''
      blocks.map((block: any) => {
        switch (block.type) {
          case 'header':
            convertedHtml += '<h' + block.data.level + '>' + block.data.text + '</h' + block.data.level + '>'
            break
          case 'paragraph':
            convertedHtml += '<p>' + block.data.text + '</p>'
            break
          case 'warning':
            convertedHtml += '<p class="warning">' + block.data.text + '</p>'
            break
          case 'image':
            convertedHtml += '<i>[Bloc Image]</i> '
            break
          case 'raw':
            convertedHtml += '<i>[Bloc HTML]</i> '
            break
          default:
            console.log('Unknown block type', block.type)
            break
        }
      })
      return convertedHtml
    },
    stripHTML (text: string) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = text
      return tmp.textContent || tmp.innerText || ''
    },
    messageSet (id: number) {
      console.log('messageSet ' + id)
      this.message = this.messages[id]
    },
    messageUpdate () {
      this.messagesGet()
    },
    messageClose () {
      console.log('messageClose')
      this.message = null
    },
    loggedOut () {
      this.$emit('loggedOut')
    }
  }
})
