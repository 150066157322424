
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Dahboard',
  data () {
    return {

    }
  },
  mounted () {
    console.log('Dashboard mounted.')
  },
  methods: {

  }
})
