
import BackofficeService from '../services/backoffice.service'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Login',
  emits: ['loggedIn'],
  data () {
    return {
      errors: [] as string[],
      email: '',
      password: ''
    }
  },
  mounted () {
    console.log('Login mounted.')
  },
  methods: {
    onLoginSubmit: function () {
      console.log('onLoginSubmit')
      this.errors = []

      if (!this.email) {
        (this.$refs.inEmail as HTMLElement).classList.add('error')
        this.errors.push('')
      }
      if (!this.password) {
        (this.$refs.inPassword as HTMLElement).classList.add('error')
        this.errors.push('')
      }

      if (!this.errors.length) {
        console.log('no error')
        const formData = new FormData()
        formData.append('email', this.email)
        formData.append('password', this.password)
        BackofficeService.sendLogin(formData).then(
          (response: any) => {
            if (response.data.user && response.data.token) {
              localStorage.setItem('user', JSON.stringify(response.data.user))
              localStorage.setItem('token', JSON.stringify(response.data.token))
              this.$emit('loggedIn')
            }
          },
          (error: any) => {
            if (error.response as any) {
              this.errors = []
              Object.entries(error.response.data.errors)
                .map(([, fieldErrors]) => {
                  if (fieldErrors instanceof Array) {
                    fieldErrors.map(fieldError => this.errors.push(fieldError))
                  }
                })
            }
          }
        )
        return true
      }
    },
    validEmail: function (email:string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
})
