
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Menu',
  emits: ['contentSet', 'logOut'],
  data () {
    return {
      errors: [] as string[],
      email: '',
      password: ''
    }
  },
  mounted () {
    console.log('Login mounted.')
  },
  methods: {
    contentSet: function (content: string) {
      console.log('contentSet : ' + content)
      this.$emit('contentSet', content)
    },
    logOut: function () {
      console.log('logOut')
      this.$emit('logOut')
    }
  }
})
