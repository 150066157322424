
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import EntityEditor from './EntityEditor.vue'

interface EntityInterface {
  id: number
  label: string
  phone: string
  email: string
  twitter: string
  instagram: string
  facebook: string
  youtube: string
}
class Entity implements EntityInterface {
  id = 0;
  label = '';
  phone = '';
  email = '';
  twitter = '';
  instagram = '';
  facebook = '';
  youtube = '';

  constructor (id: number, label: string, phone: string, email: string, twitter: string, instagram: string, facebook: string, youtube: string) {
    this.id = id
    this.label = label
    this.phone = phone
    this.email = email
    this.twitter = twitter
    this.instagram = instagram
    this.facebook = facebook
    this.youtube = youtube
  }
}

export default defineComponent({
  name: 'Entities',
  emits: ['loggedOut'],
  components: {
    EntityEditor
  },
  data () {
    return {
      entities: [],
      entity: null
    }
  },
  mounted () {
    console.log('Entities mounted.')
    this.entitiesGet()
  },
  methods: {
    entitiesGet () {
      BackofficeService.getEntitiesContent().then(
        (responses: any) => {
          this.entities = responses.data.map(
            (response: any) => this.parseAsEntity(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.loggedOut()
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    parseAsEntity (response: any) {
      return new Entity(
        response.id,
        response.label,
        response.phone,
        response.email,
        response.twitter,
        response.instagram,
        response.facebook,
        response.youtube
      )
    },
    entitySet (id: number) {
      console.log('entitySet ' + id)
      this.entity = this.entities[id]
    },
    entityUpdate () {
      this.entitiesGet()
    },
    entityClose () {
      console.log('entityClose')
      this.entity = null
    },
    loggedOut () {
      this.$emit('loggedOut')
    }
  }
})
