
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import QuestionEditor from './QuestionEditor.vue'
import ResponseEditor from './ResponseEditor.vue'

interface MobileAppInterface {
  id: number
  name: string
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';

  constructor (id: number, name: string) {
    this.id = id
    this.name = name
  }
}
interface EntityInterface {
  id: number
  label: string
}
class Entity implements EntityInterface {
  id = 0;
  label = '';

  constructor (id: number, label: string) {
    this.id = id
    this.label = label
  }
}
interface ReactionInterface {
  id: number
  responseId: number
  entity: Entity
  entityId: number
  mobileApp: MobileApp
  mobileAppId: number
  textF: string
  textH: string
  economy: number
  eod: number
  geopolitics: number
  health: number
  popularity: number
  delay: number
}
class Reaction implements ReactionInterface {
  id = 0;
  responseId = 0;
  entity = { id: 0, label: '' };
  entityId = 0;
  mobileApp = { id: 0, name: '' };
  mobileAppId = 0;
  textF = '';
  textH = '';
  economy = 0;
  eod = 0;
  geopolitics = 0;
  health = 0;
  popularity = 0;
  delay = 0;

  constructor (id: number, responseId: number, entity: Entity, mobileApp: MobileApp, textF: string, textH: string, economy: number, eod: number, geopolitics: number, health: number, popularity: number, delay: number) {
    this.id = id
    this.responseId = responseId
    this.entity = entity
    this.entityId = entity.id
    this.mobileApp = mobileApp
    this.mobileAppId = mobileApp.id
    this.textF = textF
    this.textH = textH
    this.economy = economy
    this.eod = eod
    this.geopolitics = geopolitics
    this.health = health
    this.popularity = popularity
    this.delay = delay
  }
}
interface QuestionInterface {
  id: number
  mobileApp: MobileApp
  mobileAppId: number
  entity: Entity
  entityId: number
  poolId: number
  thematic: string
  localId: number
  textF: string
  textH: string
  response1F: string
  response1H: string
  response2F: string
  response2H: string
  response3F: string
  response3H: string
  response4F: string
  response4H: string
  essential: number
  timer: number
  reactions: Reaction[]
}
class Question implements QuestionInterface {
  id = 0;
  mobileApp = { id: 0, name: '' };
  mobileAppId = 0;
  entity = { id: 0, label: '' };
  entityId = 0;
  poolId = 0;
  thematic = '';
  localId = 0;
  textF = '';
  textH = '';
  response1F = '';
  response1H = '';
  response2F = '';
  response2H = '';
  response3F = '';
  response3H = '';
  response4F = '';
  response4H = '';
  essential = 0;
  timer = 0;
  reactions = [] as Reaction[];

  constructor (id: number, mobileApp: MobileApp, entity: Entity, poolId: number, thematic: string, localId: number, textF: string, textH: string, response1F: string, response1H: string, response2F: string, response2H: string, response3F: string, response3H: string, response4F: string, response4H: string, essential: number, timer: number, reactions: Reaction[]) {
    this.id = id
    this.mobileApp = mobileApp
    this.mobileAppId = mobileApp.id
    this.entity = entity
    this.entityId = entity.id
    this.poolId = poolId
    this.thematic = thematic
    this.localId = localId
    this.textF = textF
    this.textH = textH
    this.response1F = response1F
    this.response1H = response1H
    this.response2F = response2F
    this.response2H = response2H
    this.response3F = response3F
    this.response3H = response3H
    this.response4F = response4F
    this.response4H = response4H
    this.essential = essential
    this.timer = timer
    this.reactions = reactions
  }
}

export default defineComponent({
  name: 'Questions',
  emits: ['loggedOut'],
  components: {
    QuestionEditor,
    ResponseEditor
  },
  data () {
    return {
      questions: [],
      question: Question,
      responseId: 0,
      questionShow: false,
      responseShow: false
    }
  },
  mounted () {
    console.log('Questions mounted.')
    this.questionsGet()
  },
  methods: {
    getImgUrl (img: string) {
      return 'assets/' + img
    },
    questionsGet () {
      BackofficeService.getQuestionsContent().then(
        (responses: any) => {
          this.questions = responses.data.map(
            (response: any) => this.parseAsQuestion(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.loggedOut()
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name
      )
    },
    parseAsEntity (response: any) {
      return new Entity(
        response.id,
        response.label
      )
    },
    parseAsReaction (response: any) {
      return new Reaction(
        response.id,
        response.response_id,
        this.parseAsEntity(response.entity),
        this.parseAsMobileApp(response.mobile_app),
        ((response.text_f !== '') ? JSON.parse(response.text_f) : response.text_f),
        ((response.text_h !== '') ? JSON.parse(response.text_h) : response.text_h),
        response.economy,
        response.eod,
        response.geopolitics,
        response.health,
        response.popularity,
        response.delay
      )
    },
    parseAsReactions (response: any) {
      const reactions = [] as Reaction[]
      const reactionsNb = response.length
      for (let i = 0; i < reactionsNb; i++) {
        const reaction = this.parseAsReaction(response[i])
        reactions.push(reaction)
      }
      return reactions
    },
    parseAsQuestion (response: any) {
      return new Question(
        response.id,
        this.parseAsMobileApp(response.mobile_app),
        this.parseAsEntity(response.entity),
        response.pool_id,
        response.thematic,
        response.local_id,
        ((response.text_f !== '') ? JSON.parse(response.text_f) : response.text_f),
        ((response.text_h !== '') ? JSON.parse(response.text_h) : response.text_h),
        response.response1_f,
        response.response1_h,
        response.response2_f,
        response.response2_h,
        response.response3_f,
        response.response3_h,
        response.response4_f,
        response.response4_h,
        response.essential,
        response.timer,
        this.parseAsReactions(response.reactions)
      )
    },
    getExtract (text: any) {
      if (text !== '') {
        text = this.convertDataToHtml(text.blocks)
        text = this.stripHTML(text)
        if (text.length > 50) {
          return text.substring(0, 50) + '...'
        } else {
          return text
        }
      } else {
        return text
      }
    },
    convertDataToHtml (blocks:any) {
      var convertedHtml = ''
      blocks.map((block: any) => {
        switch (block.type) {
          case 'header':
            convertedHtml += '<h' + block.data.level + '>' + block.data.text + '</h' + block.data.level + '>'
            break
          case 'paragraph':
            convertedHtml += '<p>' + block.data.text + '</p>'
            break
          case 'warning':
            convertedHtml += '<p class="warning">' + block.data.text + '</p>'
            break
          case 'image':
            convertedHtml += '<i>[Bloc Image]</i> '
            break
          case 'raw':
            convertedHtml += '<i>[Bloc HTML]</i> '
            break
          default:
            console.log('Unknown block type', block.type)
            break
        }
      })
      return convertedHtml
    },
    stripHTML (text: string) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = text
      return tmp.textContent || tmp.innerText || ''
    },
    questionSet (id: number, show: boolean) {
      console.log('questionSet ' + id)
      // this.question = this.questions[id]
      const question = this.questions.find(question => (question as Question).id === id)
      if (question) {
        this.question = question
      }
      if (show) {
        this.questionShow = true
      }
    },
    questionUpdate () {
      this.questionsGet()
    },
    questionClose () {
      console.log('questionClose')
      this.questionShow = false
      // DEBUG
      /*
      location.reload()
      */
    },
    responseSet (questionId: number, responseId: number) {
      console.log('responseSet : ' + questionId + ' | ' + responseId)
      this.questionSet(questionId, false)
      this.responseId = responseId
      this.responseShow = true
    },
    responseUpdate () {
      this.questionsGet()
    },
    responseClose () {
      console.log('response')
      this.responseShow = false
    },
    loggedOut () {
      this.$emit('loggedOut')
    }
  }
})
