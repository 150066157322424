import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Dashboard = _resolveComponent("Dashboard")!
  const _component_Apps = _resolveComponent("Apps")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_Questions = _resolveComponent("Questions")!
  const _component_Intersticiels = _resolveComponent("Intersticiels")!
  const _component_Messages = _resolveComponent("Messages")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_Login, {
          key: 0,
          onLoggedIn: _ctx.hasLoggedIn
        }, null, 8, ["onLoggedIn"]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_Menu, {
          key: 1,
          onContentSet: _ctx.contentSet,
          onLogOut: _ctx.hasLoggedOut
        }, null, 8, ["onContentSet", "onLogOut"]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'dashboard')
      ? (_openBlock(), _createBlock(_component_Dashboard, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'apps')
      ? (_openBlock(), _createBlock(_component_Apps, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'entities')
      ? (_openBlock(), _createBlock(_component_Entities, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'questions')
      ? (_openBlock(), _createBlock(_component_Questions, {
          key: 5,
          onLoggedOut: _ctx.hasLoggedOut
        }, null, 8, ["onLoggedOut"]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'intersticiels')
      ? (_openBlock(), _createBlock(_component_Intersticiels, {
          key: 6,
          onLoggedOut: _ctx.hasLoggedOut
        }, null, 8, ["onLoggedOut"]))
      : _createCommentVNode("", true),
    (_ctx.isLoggedIn && _ctx.content === 'messages')
      ? (_openBlock(), _createBlock(_component_Messages, {
          key: 7,
          onLoggedOut: _ctx.hasLoggedOut
        }, null, 8, ["onLoggedOut"]))
      : _createCommentVNode("", true)
  ]))
}