
import BackofficeService from '../services/backoffice.service'
import { defineComponent } from 'vue'

interface MobileAppInterface {
  id: number
  name: string
  type: string
  action: string
  color1: string
  color2: string
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';
  type = '';
  action = '';
  color1 = '';
  color2 = '';

  constructor (id: number, name: string, type: string, action: string, color1: string, color2: string) {
    this.id = id
    this.name = name
    this.type = type
    this.action = action
    this.color1 = color1
    this.color2 = color2
  }
}

export default defineComponent({
  name: 'Apps',
  data () {
    return {
      mobileApps: []
    }
  },
  mounted () {
    console.log('Apps mounted.')
    this.mobileAppsGet()
  },
  methods: {
    getImgUrl (img: string) {
      return 'assets/' + img
    },
    mobileAppsGet () {
      BackofficeService.getAppsContent().then(
        (responses: any) => {
          this.mobileApps = responses.data.map(
            (response: any) => this.parseAsMobileApp(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name,
        response.type,
        response.action,
        response.color1,
        response.color2
      )
    }
  }
})
