
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import LoadingButton from './LoadingButton.vue'

export default defineComponent({
  name: 'EntityEditor',
  emits: ['update', 'close', 'loggedOut'],
  props: ['entity'],
  components: {
    LoadingButton
  },
  data () {
    return {
      label: this.entity.label,
      phone: this.entity.phone,
      email: this.entity.email,
      twitter: this.entity.twitter,
      instagram: this.entity.instagram,
      facebook: this.entity.facebook,
      youtube: this.entity.youtube,
      errors: [] as string[],
      formProcessing: false
    }
  },
  mounted () {
    console.log('EntityEditor mounted.')
    setTimeout(() => {
      (document.getElementById('entityContainer') as HTMLElement).classList.add('show')
    }, 100)
  },
  methods: {
    entitySubmit: async function () {
      console.log('entitySubmit')
      this.formProcessing = true
      this.errors = []

      const formData = new FormData()
      formData.append('label', this.label)
      formData.append('phone', this.phone)
      formData.append('email', this.email)
      formData.append('twitter', this.twitter)
      formData.append('instagram', this.instagram)
      formData.append('facebook', this.facebook)
      formData.append('youtube', this.youtube)
      BackofficeService.updateEntity(this.entity.id, formData).then(
        (response: any) => {
          this.formProcessing = false
          if (response.data.success) {
            console.log('success')
            this.$emit('update')
            this.close()
          }
        },
        (error: any) => {
          if (error.response as any) {
            this.errors = []
            Object.entries(error.response.data.errors)
              .map(([, fieldErrors]) => {
                if (fieldErrors instanceof Array) {
                  fieldErrors.map(fieldError => this.errors.push(fieldError))
                }
              })
          }
        }
      )
      return true
    },
    close () {
      (document.getElementById('entityContainer') as HTMLElement).classList.remove('show')
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  }
})
