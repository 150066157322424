
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import LoadingButton from './LoadingButton.vue'
import EditorJS from '@editorjs/editorjs'
import ImageTool from '@editorjs/image'
import Warning from '@editorjs/warning'
import Header from '@editorjs/header'
import RawTool from '@editorjs/raw'

interface MobileAppInterface {
  id: number
  name: string
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';

  constructor (id: number, name: string) {
    this.id = id
    this.name = name
  }
}
interface EntityInterface {
  id: number
  label: string
}
class Entity implements EntityInterface {
  id = 0;
  label = '';

  constructor (id: number, label: string) {
    this.id = id
    this.label = label
  }
}
interface IntersticielInterface {
  id: number
  mobileApp: MobileApp
  mobileAppId: number
  entity: Entity
  entityId: number
  textF: string
  textH: string
  economy: number
  eod: number
  geopolitics: number
  health: number
  popularity: number
}
class Intersticiel implements IntersticielInterface {
  id = 0;
  mobileApp = { id: 0, name: '' };
  mobileAppId = 0;
  entity = { id: 0, label: '' };
  entityId = 0;
  textF = '';
  textH = '';
  economy = 0;
  eod = 0;
  geopolitics = 0;
  health = 0;
  popularity = 0;
  delay = 0;

  constructor (id: number, mobileApp: MobileApp, entity: Entity, textF: string, textH: string, economy: number, eod: number, geopolitics: number, health: number, popularity: number) {
    this.id = id
    this.mobileApp = mobileApp
    this.mobileAppId = mobileApp.id
    this.entity = entity
    this.entityId = entity.id
    this.textF = textF
    this.textH = textH
    this.economy = economy
    this.eod = eod
    this.geopolitics = geopolitics
    this.health = health
    this.popularity = popularity
  }
}

export default defineComponent({
  name: 'ResponseEditor',
  emits: ['update', 'close', 'loggedOut'],
  props: ['intersticiel'],
  components: {
    LoadingButton
  },
  data () {
    return {
      mobileApps: [] as MobileApp[],
      entities: [] as Entity[],
      mobileAppId: this.intersticiel.mobileAppId,
      entityId: this.intersticiel.entityId,
      essential: this.intersticiel.essential > 0,
      timer: this.intersticiel.timer,
      textF: this.intersticiel.textF,
      textH: this.intersticiel.textH,
      errors: [] as string[],
      editorF: {} as EditorJS,
      editorH: {} as EditorJS,
      popularity: 0,
      economy: 0,
      health: 0,
      geopolitics: 0,
      eod: 0,
      token: '',
      formProcessing: false
    }
  },
  mounted () {
    console.log('IntersticielEditor mounted.')
    setTimeout(() => {
      (document.getElementById('intersticielContainer') as HTMLElement).classList.add('show')
    }, 100)
    this.token = JSON.parse(localStorage.getItem('token') as string)
    this.editorF = new EditorJS({
      holder: 'editorjsF',
      data: this.textF,
      tools: {
        image: {
          class: ImageTool,
          config: {
            additionalRequestHeaders: {
              Authorization: 'Bearer ' + this.token
            },
            endpoints: {
              byFile: 'https://api.callmepresident.fr/api/question/image',
              byUrl: 'http://localhost:8008/fetchUrl'
            }
          }
        },
        warning: Warning,
        header: Header,
        raw: RawTool
      }
    })
    this.editorH = new EditorJS({
      holder: 'editorjsH',
      data: this.textH,
      tools: {
        image: {
          class: ImageTool,
          config: {
            additionalRequestHeaders: {
              Authorization: 'Bearer ' + this.token
            },
            endpoints: {
              byFile: 'https://api.callmepresident.fr/api/question/image',
              byUrl: 'http://localhost:8008/fetchUrl'
            }
          }
        },
        warning: Warning,
        header: Header,
        raw: RawTool
      }
    })
    this.mobileAppsGet()
    this.entitiesGet()
  },
  methods: {
    getImgUrl (img: string) {
      return 'assets/' + img
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name
      )
    },
    parseAsEntity (response: any) {
      return new Entity(
        response.id,
        response.label
      )
    },
    mobileAppsGet () {
      console.log('mobileAppsGet')
      BackofficeService.getAppsContent().then(
        (responses: any) => {
          this.mobileApps = responses.data.map(
            (response: any) => this.parseAsMobileApp(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    entitiesGet () {
      console.log('entitiesGet')
      BackofficeService.getEntitiesContent().then(
        (responses: any) => {
          this.entities = responses.data.map(
            (response: any) => this.parseAsEntity(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    intersticielSubmit: async function () {
      console.log('intersticielSubmit')
      this.formProcessing = true
      this.errors = []
      const savedTextF = await this.saveTextF()
      const savedTextH = await this.saveTextH()

      const formData = new FormData()
      formData.append('mobileapp_id', this.mobileAppId)
      formData.append('entity_id', this.entityId)
      formData.append('text_f', JSON.stringify(savedTextF))
      formData.append('text_h', JSON.stringify(savedTextH))
      formData.append('economy', this.economy.toString())
      formData.append('eod', this.eod.toString())
      formData.append('geopolitics', this.geopolitics.toString())
      formData.append('health', this.health.toString())
      formData.append('popularity', this.popularity.toString())
      formData.append('essential', (this.essential) ? '1' : '0')
      formData.append('timer', this.timer)
      BackofficeService.updateIntersticiel(this.intersticiel.id, formData).then(
        (response: any) => {
          this.formProcessing = false
          if (response.data.success) {
            console.log('success')
            this.$emit('update')
            this.close()
          }
        },
        (error: any) => {
          if (error.response as any) {
            this.errors = []
            Object.entries(error.response.data.errors)
              .map(([, fieldErrors]) => {
                if (fieldErrors instanceof Array) {
                  fieldErrors.map(fieldError => this.errors.push(fieldError))
                }
              })
          }
        }
      )
      return true
    },
    saveTextF () {
      return new Promise(resolve => {
        this.editorF.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    saveTextH () {
      return new Promise(resolve => {
        this.editorH.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    getExtract (text: any) {
      if (text !== '') {
        if (text.length > 50) {
          return text.substring(0, 50) + '...'
        } else {
          return text
        }
      } else {
        return text
      }
    },
    close () {
      this.editorF.destroy()
      this.editorH.destroy();
      (document.getElementById('intersticielContainer') as HTMLElement).classList.remove('show')
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  }
})
