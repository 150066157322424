
import { defineComponent } from 'vue'
import BackofficeService from '../services/backoffice.service'
import LoadingButton from './LoadingButton.vue'
import EditorJS from '@editorjs/editorjs'
import ImageTool from '@editorjs/image'
import Warning from '@editorjs/warning'
import Header from '@editorjs/header'
import RawTool from '@editorjs/raw'

interface MobileAppInterface {
  id: number
  name: string
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';

  constructor (id: number, name: string) {
    this.id = id
    this.name = name
  }
}
interface EntityInterface {
  id: number
  label: string
}
class Entity implements EntityInterface {
  id = 0;
  label = '';

  constructor (id: number, label: string) {
    this.id = id
    this.label = label
  }
}
interface ReactionInterface {
  id: number
  entityId: number
  mobileAppId: number
  textF: string
  textH: string
  popularity: number
  economy: number
  health: number
  geopolitics: number
  eod: number
  delay: number
}
class Reaction implements ReactionInterface {
  id = 0;
  entityId = 0;
  mobileAppId = 0;
  textF = ''
  textH = ''
  popularity = 0;
  economy = 0;
  health = 0;
  geopolitics = 0;
  eod = 0;
  delay = 0;

  constructor (id: number, entityId: number, mobileAppId: number, textF: string, textH: string, popularity: number, economy: number, health: number, geopolitics: number, eod: number, delay: number) {
    this.id = id
    this.entityId = entityId
    this.mobileAppId = mobileAppId
    this.textF = textF
    this.textH = textH
    this.popularity = popularity
    this.economy = economy
    this.health = health
    this.geopolitics = geopolitics
    this.eod = eod
    this.delay = delay
  }
}

export default defineComponent({
  name: 'ResponseEditor',
  emits: ['update', 'close', 'loggedOut'],
  props: ['question', 'responseId'],
  components: {
    LoadingButton
  },
  data () {
    return {
      mobileApps: [] as MobileApp[],
      entities: [] as Entity[],
      reactions: [] as Reaction[],
      textF: this.question['response' + this.responseId + 'F'],
      textH: this.question['response' + this.responseId + 'H'],
      errors: [] as string[],
      reactionId1: -1,
      mobileAppId1: 0,
      entityId1: 0,
      editor1F: {} as EditorJS,
      editor1H: {} as EditorJS,
      text1F: '',
      text1H: '',
      popularity1: 0,
      economy1: 0,
      health1: 0,
      geopolitics1: 0,
      eod1: 0,
      delay1: 0,
      reactionId2: -1,
      mobileAppId2: 0,
      entityId2: 0,
      editor2F: {} as EditorJS,
      editor2H: {} as EditorJS,
      text2F: '',
      text2H: '',
      popularity2: 0,
      economy2: 0,
      health2: 0,
      geopolitics2: 0,
      eod2: 0,
      delay2: 0,
      token: '',
      formProcessing: false
    }
  },
  mounted () {
    console.log('ResponseEditor mounted.')
    setTimeout(() => {
      (document.getElementById('responseContainer') as HTMLElement).classList.add('show')
    }, 100)
    this.token = JSON.parse(localStorage.getItem('token') as string)
    this.reactionsGet()
    this.mobileAppsGet()
    this.entitiesGet()
  },
  watch: {
    reactions (newValue, oldValue) {
      if (this.reactions.length > 0) {
        this.reactionId1 = this.reactions[0].id
        this.mobileAppId1 = this.reactions[0].mobileAppId
        this.entityId1 = this.reactions[0].entityId
        this.popularity1 = this.reactions[0].popularity
        this.economy1 = this.reactions[0].economy
        this.health1 = this.reactions[0].health
        this.geopolitics1 = this.reactions[0].geopolitics
        this.eod1 = this.reactions[0].eod
        this.delay1 = this.reactions[0].delay
        this.editor1F = new EditorJS({
          holder: 'editorjs1F',
          data: JSON.parse(JSON.stringify(this.reactions[0].textF)),
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
        this.editor1H = new EditorJS({
          holder: 'editorjs1H',
          data: JSON.parse(JSON.stringify(this.reactions[0].textH)),
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
        if (this.reactions.length > 1) {
          this.reactionId2 = this.reactions[1].id
          this.mobileAppId2 = this.reactions[1].mobileAppId
          this.entityId2 = this.reactions[1].entityId
          this.popularity2 = this.reactions[1].popularity
          this.economy2 = this.reactions[1].economy
          this.health2 = this.reactions[1].health
          this.geopolitics2 = this.reactions[1].geopolitics
          this.eod2 = this.reactions[1].eod
          this.delay2 = this.reactions[1].delay
          this.editor2F = new EditorJS({
            holder: 'editorjs2F',
            data: JSON.parse(JSON.stringify(this.reactions[1].textF)),
            tools: {
              image: {
                class: ImageTool,
                config: {
                  additionalRequestHeaders: {
                    Authorization: 'Bearer ' + this.token
                  },
                  endpoints: {
                    byFile: 'https://api.callmepresident.fr/api/reaction/image',
                    byUrl: 'http://localhost:8008/fetchUrl'
                  }
                }
              },
              warning: Warning,
              header: Header,
              raw: RawTool
            }
          })
          this.editor2H = new EditorJS({
            holder: 'editorjs2H',
            data: JSON.parse(JSON.stringify(this.reactions[1].textH)),
            tools: {
              image: {
                class: ImageTool,
                config: {
                  additionalRequestHeaders: {
                    Authorization: 'Bearer ' + this.token
                  },
                  endpoints: {
                    byFile: 'https://api.callmepresident.fr/api/reaction/image',
                    byUrl: 'http://localhost:8008/fetchUrl'
                  }
                }
              },
              warning: Warning,
              header: Header,
              raw: RawTool
            }
          })
        } else {
          this.editor2F = new EditorJS({
            holder: 'editorjs2F',
            tools: {
              image: {
                class: ImageTool,
                config: {
                  additionalRequestHeaders: {
                    Authorization: 'Bearer ' + this.token
                  },
                  endpoints: {
                    byFile: 'https://api.callmepresident.fr/api/reaction/image',
                    byUrl: 'http://localhost:8008/fetchUrl'
                  }
                }
              },
              warning: Warning,
              header: Header,
              raw: RawTool
            }
          })
          this.editor2H = new EditorJS({
            holder: 'editorjs2H',
            tools: {
              image: {
                class: ImageTool,
                config: {
                  additionalRequestHeaders: {
                    Authorization: 'Bearer ' + this.token
                  },
                  endpoints: {
                    byFile: 'https://api.callmepresident.fr/api/reaction/image',
                    byUrl: 'http://localhost:8008/fetchUrl'
                  }
                }
              },
              warning: Warning,
              header: Header,
              raw: RawTool
            }
          })
        }
      } else {
        this.editor1F = new EditorJS({
          holder: 'editorjs1F',
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
        this.editor1H = new EditorJS({
          holder: 'editorjs1H',
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
        this.editor2F = new EditorJS({
          holder: 'editorjs2F',
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
        this.editor2H = new EditorJS({
          holder: 'editorjs2H',
          tools: {
            image: {
              class: ImageTool,
              config: {
                additionalRequestHeaders: {
                  Authorization: 'Bearer ' + this.token
                },
                endpoints: {
                  byFile: 'https://api.callmepresident.fr/api/reaction/image',
                  byUrl: 'http://localhost:8008/fetchUrl'
                }
              }
            },
            warning: Warning,
            header: Header,
            raw: RawTool
          }
        })
      }
    }
  },
  methods: {
    getImgUrl (img: string) {
      return 'assets/' + img
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name
      )
    },
    parseAsEntity (response: any) {
      return new Entity(
        response.id,
        response.label
      )
    },
    parseAsReaction (response: any) {
      return new Reaction(
        response.id,
        response.entity_id,
        response.mobileapp_id,
        ((response.text_f !== '') ? JSON.parse(response.text_f) : response.text_f),
        ((response.text_h !== '') ? JSON.parse(response.text_h) : response.text_h),
        response.popularity,
        response.economy,
        response.health,
        response.geopolitics,
        response.eod,
        response.delay
      )
    },
    reactionsGet () {
      console.log('reactionsGet')
      BackofficeService.getReactionsContent(this.question.id, this.responseId).then(
        (responses: any) => {
          this.reactions = responses.data.map(
            (response: any) => this.parseAsReaction(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    mobileAppsGet () {
      console.log('mobileAppsGet')
      BackofficeService.getAppsContent().then(
        (responses: any) => {
          this.mobileApps = responses.data.map(
            (response: any) => this.parseAsMobileApp(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    entitiesGet () {
      console.log('entitiesGet')
      BackofficeService.getEntitiesContent().then(
        (responses: any) => {
          this.entities = responses.data.map(
            (response: any) => this.parseAsEntity(response)
          )
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('error status : ' + error.response.status)
            alert('User not authorized')
            this.$emit('loggedOut')
          }
          if (error.response) {
            console.log(error.response)
          }
        }
      )
    },
    responseSubmit: async function () {
      console.log('responseSubmit')
      this.formProcessing = true
      this.errors = []
      const savedText1F = await this.saveText1F()
      const savedText1H = await this.saveText1H()
      const savedText2F = await this.saveText2F()
      const savedText2H = await this.saveText2H()

      const formData = new FormData()
      formData.append('text_f', this.textF)
      formData.append('text_h', this.textH)
      formData.append('reaction_id1', this.reactionId1.toString())
      formData.append('mobileapp_id1', this.mobileAppId1.toString())
      formData.append('entity_id1', this.entityId1.toString())
      formData.append('text1_f', JSON.stringify(savedText1F))
      formData.append('text1_h', JSON.stringify(savedText1H))
      formData.append('popularity1', this.popularity1.toString())
      formData.append('economy1', this.economy1.toString())
      formData.append('health1', this.health1.toString())
      formData.append('geopolitics1', this.geopolitics1.toString())
      formData.append('eod1', this.eod1.toString())
      formData.append('delay1', this.delay1.toString())
      formData.append('reaction_id2', this.reactionId2.toString())
      formData.append('mobileapp_id2', this.mobileAppId2.toString())
      formData.append('entity_id2', this.entityId2.toString())
      formData.append('text2_f', JSON.stringify(savedText2F))
      formData.append('text2_h', JSON.stringify(savedText2H))
      formData.append('popularity2', this.popularity2.toString())
      formData.append('economy2', this.economy2.toString())
      formData.append('health2', this.health2.toString())
      formData.append('geopolitics2', this.geopolitics2.toString())
      formData.append('eod2', this.eod2.toString())
      formData.append('delay2', this.delay2.toString())
      BackofficeService.updateResponse(this.question.id, this.responseId, formData).then(
        (response: any) => {
          this.formProcessing = false
          if (response.data.success) {
            console.log('success')
            this.$emit('update')
            this.close()
          }
        },
        (error: any) => {
          if (error.response as any) {
            this.errors = []
            Object.entries(error.response.data.errors)
              .map(([, fieldErrors]) => {
                if (fieldErrors instanceof Array) {
                  fieldErrors.map(fieldError => this.errors.push(fieldError))
                }
              })
          }
        }
      )
      return true
    },
    saveText1F () {
      return new Promise(resolve => {
        this.editor1F.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    saveText1H () {
      return new Promise(resolve => {
        this.editor1H.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    saveText2F () {
      return new Promise(resolve => {
        this.editor2F.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    saveText2H () {
      return new Promise(resolve => {
        this.editor2H.save().then((outputData) => {
          resolve(outputData)
        }).catch((error) => {
          console.log('Saving failed: ', error)
        })
      })
    },
    getExtract (text: any) {
      if (text !== '') {
        if (text.length > 50) {
          return text.substring(0, 50) + '...'
        } else {
          return text
        }
      } else {
        return text
      }
    },
    close () {
      this.editor1F.destroy()
      this.editor1H.destroy()
      this.editor2F.destroy()
      this.editor2H.destroy();
      (document.getElementById('responseContainer') as HTMLElement).classList.remove('show')
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    }
  }
})
